import { render, staticRenderFns } from "./DeletePromptModal.vue?vue&type=template&id=a25eb104&scoped=true"
import script from "./DeletePromptModal.vue?vue&type=script&lang=js"
export * from "./DeletePromptModal.vue?vue&type=script&lang=js"
import style0 from "./DeletePromptModal.vue?vue&type=style&index=0&id=a25eb104&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a25eb104",
  null
  
)

export default component.exports
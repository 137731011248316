
<template>
	<div>
		<b-modal :id="modalId"
			@close="closeModal"
			no-close-on-backdrop
			no-close-on-esc>
			<template #modal-title>
				{{ modalTitle }}
			</template>

			<div v-if="verifyingDelete">
				<div class="d-flex flex-column justify-content-center align-items-center">
					<div class="spinner-border" role="status"></div>
					<p class="text-center mt-4">{{checkProcessLabel}}</p>
				</div>
			</div>

			<div class="py-2" v-else>

				<div class="alert alert-info d-flex align-items-center" role="alert">
					<img class="mr-2" src="@/assets/img/caution.svg">
					<p class="mb-0">{{verificationMessage}}</p>
				</div>

				<p class="mb-3">{{promptText}}</p>

				<p class="mb-2 prompt-message" v-if="showPromptKeyLabel">Please type <b>{{promptKey}}</b> to confirm this action.</p>
				<div class="form mb-3">
					<div class="form-group">
						<input v-model="form.password" class="form-control mb-3" :placeholder="`Type ${promptKey}`" :type="isPasswordType ? 'password': 'text'" autocomplete="new-password">
					</div>
				</div>
			</div>

			<template #modal-footer>
				<div class="w-100">
					<b-button
						:disabled="processingAction || verifyingDelete || !isValidForm"
						variant="primary"
						class="float-right"
						@click="deleteData()">
						{{ processingAction ? 'Processing' : 'Proceed' }}
					</b-button>
					<b-button
						:disabled="loading || verifyingDelete"
						variant="secondary"
						class="float-right mr-2"
						@click="closeModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
export default {
  name: 'DeletePromptModal',
  props: {
    show: Boolean,
    indexKey: [String, Number],
    modalTitle: String,
    checkProcessLabel: String,
    verifyingDelete: Boolean,
    verificationMessage: String,
    deleteFunction: Function,
    processingAction: Boolean,
    isPasswordType: {
      type: Boolean,
      default: false
    },
    promptText: { type: String, default: 'If you want to delete this item, type YES to proceed', required: false },
    promptKey: {
      type: String,
      required: false,
      default: 'YES'
    },
    showPromptKeyLabel: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      form: {
        password: null
      },
      modalKey: 'delete-prompt-modal',
      loading: false
    }
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) {
          this.openModal()
        } else {
          this.closeModal()
        }
      }
    }
  },
  computed: {
    isValidForm () {
      if (this.isPasswordType) {
        return !!this.form.password
      }
      return !!this.form.password && this.form.password === this.promptKey
    },
    modalId () {
      return this.indexKey ? `prompt-modal-${this.indexKey}-${this.modalKey}-${this.indexKey}` : this.modalKey
    }
  },
  methods: {
    patchForm () {
      this.resetForm()
    },
    resetForm () {
      this.form.password = null
    },
    openModal () {
      this.$bvModal.show(this.modalId)
      if (this.model) {
        this.patchForm(this.model)
      }
    },
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.$emit('close')
      this.resetForm()
    },
    deleteData () {
      if (!this.isValidForm) {
        return
      }
      if (this.isPasswordType) {
        this.deleteFunction(this.form.password)
      } else {
        this.deleteFunction()
      }
    }

  }
}
</script>

<style lang="scss" scoped>

.alert-info {
  $purple-200: #EBEBFF;
  $purple-800: #2323F0;
  background: $purple-200;
  color: $purple-800;
  border: none;
}
</style>

<template>
  <div class='card'>
    <div class="card-body p-0">
      <b-table ref="staffTable" :current-page="currentPage" :busy="routeLoading" responsive striped :items="inspectionSites" :fields="fields">
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <strong>Loading...</strong>
          </div>
        </template>
        <template #empty>
          <h4>There are no staff found</h4>
        </template>
        <template #cell(suspension_data)="data">
          {{ !data.item.suspension_data?.routes?.length ? 'All' : data.item.suspension_data.routes.map((route) => route.route_code ?? 'N/A').join(', ') }}
        </template>
        <!-- <template #cell(created_at)="data">
            {{ moment(data.value).format('dddd, MMMM Do YYYY') }}
        </template> -->
      
        <template #cell(id)="data">
            <!-- <button class="btn btn-outline-primary btn-sm mr-2" @click="openEditModal(data.item)" v-if="userHasPermission('inspection002')"> -->
            <button class="btn btn-outline-primary btn-sm mr-2" @click="openEditModal(data.item)" >
                Edit
              </button>
              <!-- <button class="btn btn-outline-danger btn-sm" @click="openDelModal(data.item)" v-if="userHasPermission('inspection005')"> -->
              <button class="btn btn-outline-danger btn-sm" @click="openDelModal(data.item)" >
                Delete
              </button>
        </template>
      </b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
    </div>
    <delete-prompt-modal :modal-title="deleteObj.title" 
    @close="deleteObj.show.value = false" :check-process-label="deleteObj.label"
     :verifying-delete="deleteObj.verifyingDelete.value" :verification-message="deleteObj.verificationMessage" 
     :processing-action="deleteObj.deletingSite.value"
    :show="deleteObj.show.value" :prompt-text="deleteObj.text" 
    :delete-function="deleteSite">
      </delete-prompt-modal>
          <BS_modal :show='editObj.show.value' title='Edit Inspection Site' @close='editObj.show.value = false' modalId='EditInspectionModal'>
      <div class="col-12">
        <div class="form-group relative">
          <label class="mb-1">Name</label>
          <input required type="text" class="form-control" v-model='selectedSite.name' />
        </div>
        <div class="form-group relative">
          <label class="mb-1">Address</label>
          <vue-google-autocomplete  id="startingPointAddressInput" v-model='selectedSite.address' classname="form-control z-[999999]" placeholder="Starting Point" v-on:placechanged="originChanged" types="" country="ng">
          </vue-google-autocomplete>
        </div>
      </div>

      <div class="col-12">
        <button class='btn bg-dark text-white w-full' @click='editSite' :disabled="selectedSite.name === '' || selectedSite.address === '' || editObj.editingSite.value">
          {{ editObj.editingSite.value ? 'loading...' : 'Edit Site' }}
        </button>
      </div>
    </BS_modal>
  </div>
</template>

<script setup>
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import { userHasPermission } from '@/composables/core/permissions'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import Swal from 'sweetalert2'
import { ref, watch } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import { useInspectionSite } from './composable'
import VueGoogleAutocomplete from 'vue-google-autocomplete'


const selectedSite = ref({})

const openDelModal = (site) => {
  selectedSite.value = site
  deleteObj.show.value = true
}
const openEditModal = (site) => {
  selectedSite.value = site
  editObj.show.value = true
}
const editObj = {
  show: ref(false),
  editingSite: ref(false),
}
const originChanged = (_, place) =>{
  selectedSite.value.address = place.formatted_address
  selectedSite.value.geo_coordinate = `${place.geometry.location.lat()},${place.geometry.location.lng()}`
}
const editSite = () => {
  editObj.editingSite.value = true
  axiosInstance.patch(`/v1/inspection-sites/${selectedSite.value.id}`, {
    name: selectedSite.value.name,
    address: selectedSite.value.address,
    geo_coordinate: selectedSite.value.geo_coordinate,
  })
    .then((res) => {
      editObj.editingSite.value = false
      editObj.show.value = false
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Inspection Site updated successfully',
      })

      getSites()
    })
    .catch((err) => {
      editObj.editingSite.value = false
      editObj.show.value = false
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
      })
    })
}
const deleteObj = {
  show: ref(false),
  title: 'Are you sure you want to delete ?',
  text: 'If you want to delete this inspection site, type YES to proceed',
  label: 'Checking vehicles attached to category...',
  deletingSite: ref(false),
  verifyingDelete: ref(false),
  verificationMessage: 'You are about to delete this inspection Site',
}
const deleteSite = () => {
  deleteObj.deletingSite.value = true
  axiosInstance.delete(`/v1/inspection-sites/${selectedSite.value.id}`)
    .then((res) => {
      deleteObj.deletingSite.value = false
      deleteObj.show.value = false
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Inspection Site deleted successfully',
      })

      getSites()
    })
    .catch((err) => {
      deleteObj.deletingSite.value = false
      deleteObj.show.value = false
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong',
      })
    })
}

const { currentPage, getSites, routeLoading, inspectionSites, perPage, totalRecords } = useInspectionSite()
getSites()

const fields = [
  { key: 'name', label: 'Name' },
  { key: 'address', label: 'Address' },
  // { key: 'created_at', label: 'Date' },
  { key: 'id', label: 'Action' },
]

watch(currentPage, () => {
  getSites()
})
</script>

<style scoped></style>
